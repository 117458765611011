/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';

import { consumerGetByIdOrganizationIdGet } from '../fn/consumer/consumer-get-by-id-organization-id-get';
import { ConsumerGetByIdOrganizationIdGet$Params } from '../fn/consumer/consumer-get-by-id-organization-id-get';
import { consumerGetByShortCodeShortCodeGet } from '../fn/consumer/consumer-get-by-short-code-short-code-get';
import { ConsumerGetByShortCodeShortCodeGet$Params } from '../fn/consumer/consumer-get-by-short-code-short-code-get';
import { consumerGetByTokenTokenGet } from '../fn/consumer/consumer-get-by-token-token-get';
import { ConsumerGetByTokenTokenGet$Params } from '../fn/consumer/consumer-get-by-token-token-get';
import { OrganizationCodeModel } from '../models/organization-code-model';
import { OrganizationTokenModel } from '../models/organization-token-model';

@Injectable({ providedIn: 'root' })
export class OrganizationConsumerService extends OrganizationBaseService {
  constructor(config: OrganizationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `consumerGetByShortCodeShortCodeGet()` */
  static readonly ConsumerGetByShortCodeShortCodeGetPath = '/Consumer/GetByShortCode/{shortCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGetByShortCodeShortCodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetByShortCodeShortCodeGet$Response(params: ConsumerGetByShortCodeShortCodeGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationCodeModel>> {
    return consumerGetByShortCodeShortCodeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerGetByShortCodeShortCodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetByShortCodeShortCodeGet(params: ConsumerGetByShortCodeShortCodeGet$Params, context?: HttpContext): Observable<OrganizationCodeModel> {
    return this.consumerGetByShortCodeShortCodeGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationCodeModel>): OrganizationCodeModel => r.body)
    );
  }

  /** Path part for operation `consumerGetByTokenTokenGet()` */
  static readonly ConsumerGetByTokenTokenGetPath = '/Consumer/GetByToken/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGetByTokenTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetByTokenTokenGet$Response(params: ConsumerGetByTokenTokenGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationTokenModel>> {
    return consumerGetByTokenTokenGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerGetByTokenTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetByTokenTokenGet(params: ConsumerGetByTokenTokenGet$Params, context?: HttpContext): Observable<OrganizationTokenModel> {
    return this.consumerGetByTokenTokenGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationTokenModel>): OrganizationTokenModel => r.body)
    );
  }

  /** Path part for operation `consumerGetByIdOrganizationIdGet()` */
  static readonly ConsumerGetByIdOrganizationIdGetPath = '/Consumer/GetById/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGetByIdOrganizationIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetByIdOrganizationIdGet$Response(params: ConsumerGetByIdOrganizationIdGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<OrganizationTokenModel>> {
    return consumerGetByIdOrganizationIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerGetByIdOrganizationIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGetByIdOrganizationIdGet(params: ConsumerGetByIdOrganizationIdGet$Params, context?: HttpContext): Observable<OrganizationTokenModel> {
    return this.consumerGetByIdOrganizationIdGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<OrganizationTokenModel>): OrganizationTokenModel => r.body)
    );
  }

}
