import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { EventService } from '../events/event.service';
import { NavController } from '@ionic/angular';
import { UserInfoService } from './user-info-service';
import { Semaphore } from 'src/app/utilities/semaphore/semaphore.utility';
import { EnvironmentConfigService } from '../environment-config-service/environment-config.service';
import { AuthenticationLocalAccountService } from 'src/app/api/proxy/auth/authentication-services';
import { AccessTokenModel, ChangePasswordRequestModel, ForgotPasswordRequestModel, LoginRequestModel, RefreshTokenRequestModel, RefreshTokenResponseModel, RequestOtpModel, RequestOtpResponseModel, ResetPasswordRequestModel, VerifyOtpRequestModel } from 'src/app/api/proxy/auth/authentication-models';
import { firstValueFrom, of, timeout } from 'rxjs';
import { addSeconds, differenceInSeconds, formatISO, isAfter, parseISO } from 'date-fns';
import { BiometricLoginDetail, UserData } from './user-data.model';
import { Capacitor } from '@capacitor/core';
import { SystemLogService } from '../systemlog-service/systemlog.service';
import { PromptService } from '../promtp-service/prompt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private NETWORK_TIMEOUT = 10000;
  constructor(
    private events: EventService,
    private secureStorageService: SecureStorageService,
    public navCtrl: NavController,
    private _userInfoService: UserInfoService,
    private _EnvironmentConfigService: EnvironmentConfigService,
    private _localAccountService: AuthenticationLocalAccountService,
    private _systemLogService: SystemLogService,
    private _PromptService: PromptService
  ) {
    this.events.appPaused.subscribe(() => {
      this.clearRefresh();
    });

    this.events.appResumed.subscribe(() => {
      this.setupRefreshTimer();
    });
  }

  private initialized = false;

  public async init(): Promise<void> {
    // Simulate some initialization logic (e.g., checking token, refreshing, etc.)
    this.initialized = true;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public async tokenExpired(): Promise<boolean> {
    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo && userInfo.tokenExpiry && userInfo.access_token) {
      const dt = parseISO(userInfo.tokenExpiry);
      const compareDate = new Date();
      if (isAfter(dt, compareDate)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  public async token(): Promise<string | null> {
    var userInfo = await this._userInfoService.getUserInfo();
    if (userInfo && userInfo.access_token) {
      return userInfo.access_token;
    }
    return null;
  }

  public async saveBiometricLogin(countryCode: string, email: string, password: string) {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    var biometricLogin: BiometricLoginDetail = {
      username: email,
      password: password,
      countrycode: countryCode
    };
    await this.secureStorageService.set(SecureStorageKey.BiometricLogin, JSON.stringify(biometricLogin));
  }

  public async getBiometricLogin(): Promise<BiometricLoginDetail | null> {
    if (Capacitor.getPlatform() === 'web') {
      return null;
    }

    var userData = await this.secureStorageService.get(
      SecureStorageKey.BiometricLogin
    );

    if (!userData || userData.length === 0 || userData === '') {
      return null;
    }
    var userInfo = JSON.parse(userData) as BiometricLoginDetail;
    if (!userInfo) {
      return null;
    }

    return userInfo;
  }

  public async clearBiometricLogin(): Promise<void> {
    var bioData = await this.getBiometricLogin();
    if (bioData) {
      await this.secureStorageService.set(SecureStorageKey.BiometricLogin, '');
      await this.secureStorageService.remove(SecureStorageKey.BiometricLogin);
    }
  }

  public async login(emailAddress: string, pasw: string): Promise<boolean> {


    try {
      const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration("auth-service-login");
      if (!envConfig) {
        return false;
      }

      const loginRequest: LoginRequestModel = {
        applicationId: envConfig.authenticateOptions!.appId!,
        applicationScope: envConfig.authenticateOptions!.scope!,
        emailAddress: emailAddress,
        password: pasw,
      };

      var response: AccessTokenModel | null;

      try {
        response = await firstValueFrom(this._localAccountService.localAccountLoginUserPost({ body: loginRequest })
          .pipe(timeout(this.NETWORK_TIMEOUT)));

      } catch (error) {
        await this._PromptService.showNetworkConnectionError(error,'login');
        return false;
      }

      if (response && response.access_token && response.access_token.length > 0 && !response.error) {
        const tokenResponse = response as AccessTokenModel;

        if (!tokenResponse.expires_in) {
          tokenResponse.expires_in = (55 * 60).toString();
        }

        const expires_in = parseInt(tokenResponse.expires_in) * 0.75;
        const dt = addSeconds(new Date(), expires_in);
        const expiry = formatISO(dt);

        const token = this.parseJwt(tokenResponse.access_token);
        const userInfo: UserData = {
          sub: token.oid,
          given_name: token.given_name,
          family_name: token.family_name,
          name: token.name,
          time: formatISO(new Date()),
          access_token: tokenResponse.access_token!,
          refresh_token: tokenResponse.refresh_token!,
          tokenExpiry: expiry,
          waitTime: expires_in,
          tokenClaims: token,
          email: token.userEmailAddress!,
          language: token.language,
          systemFunctions: token.systemFunctions
        };

        var oldUser = await this._userInfoService.getUserInfo();
        if (oldUser && oldUser.sub !== userInfo.sub) {
          await this.secureStorageService.clear();
        }
        await this._userInfoService.setUserInfo(userInfo);
        await this.setUserLoggedInAsync();
        return true; // Login successful
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    }
    return false;
  }

  public async forgotPasswordRequest(email: string): Promise<boolean> {
    try {
      var model: ForgotPasswordRequestModel = { emailAddress: email };
      const response = await firstValueFrom(this._localAccountService.localAccountForgotPasswordRequestPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error,'forgotPasswordRequest');
      return false;
    }
  }

  public async resetForgotPassword(email: string, password: string, otp: number): Promise<boolean> {
    try {
      var model: ResetPasswordRequestModel = { emailAddress: email, newPassword: password, otp: otp };
      const response = await firstValueFrom(this._localAccountService.localAccountResetForgotPasswordPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      this.clearBiometricLogin();
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error,'resetForgotPassword');
      return false;
    }
  }

  public async changePassword(password: string) {
    try {
      var model: ChangePasswordRequestModel = { newPassword: password };
      const response = await firstValueFrom(
        this._localAccountService.localAccountChangePasswordPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));

      var bioPassword = await this.getBiometricLogin();
      if (bioPassword) {
        await this.saveBiometricLogin(bioPassword.countrycode, bioPassword.username, password);
      }
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error,'changePassword');
      return false;
    }
  }

  public async registerProfile(tokenResponse: AccessTokenModel): Promise<boolean> {
    try {

      if (tokenResponse && tokenResponse.access_token && tokenResponse.access_token.length > 0 && !tokenResponse.error) {
        if (!tokenResponse.expires_in) {
          tokenResponse.expires_in = (55 * 60).toString();
        }

        const expires_in = parseInt(tokenResponse.expires_in) * 0.75;
        const dt = addSeconds(new Date(), expires_in);
        const expiry = formatISO(dt);

        const token = this.parseJwt(tokenResponse.access_token);
        const userInfo: UserData = {
          sub: token.oid,
          given_name: token.given_name,
          family_name: token.family_name,
          name: token.name,
          time: formatISO(new Date()),
          access_token: tokenResponse.access_token!,
          refresh_token: tokenResponse.refresh_token!,
          tokenExpiry: expiry,
          waitTime: expires_in,
          tokenClaims: token,
          email: token.userEmailAddress!,
          language: token.language,
          systemFunctions: token.systemFunctions
        };

        var oldUser = await this._userInfoService.getUserInfo();
        if (oldUser && oldUser.sub !== userInfo.sub) {
          await this.secureStorageService.clear();
        }
        await this._userInfoService.setUserInfo(userInfo);
        await this.setUserLoggedInAsync();
        return true; // Login successful
      } else {
        throw new Error("Unexpected error occurred");
      }
    } catch (error) {
      this._systemLogService.logError(error);
      return false;
    }
  }

  public async requestOTP(model: RequestOtpModel): Promise<RequestOtpResponseModel | null> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountRequestOtpPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return response;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error,'requestOTP');
      return null;
    }
  }

  public async verifyOTP(model: VerifyOtpRequestModel): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountVerifyOtpAsyncPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return response == true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error,'verifyOTP');
      return false;
    }
  }

  private parseJwt(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  private lock = new Semaphore(1);

  public async refreshToken(timerRefresh: boolean): Promise<void> {

    await this.lock.callFunction(async (_: any) => {
      await this.refresh(timerRefresh);
    }, []);

  }

  public async logout(): Promise<void> {
    await this.clearUserData(false);

    setTimeout(() => {
      window.location.href = '/welcome'; // Redirects to the root URL
    }, 1000);
  }

  public async clearUserData(clearBiologin: boolean = true): Promise<void> {
    await this._userInfoService.setUserInfo(null);
    this.clearRefresh(); //terminate timers 

    var region = await this.secureStorageService.get(SecureStorageKey.CurrentRegion);
    var bioLogin = await this.secureStorageService.get(SecureStorageKey.BiometricLogin);
    this.secureStorageService.clear();
    if (bioLogin && !clearBiologin) {
      await this.secureStorageService.set(SecureStorageKey.BiometricLogin, bioLogin);
    }
    if (region) {
      await this.secureStorageService.set(SecureStorageKey.CurrentRegion, region);
      await this._EnvironmentConfigService.EnsureNetworkConfiguration("auth-service-clearUserData");
    }
  }


  private async refresh(timerRefresh: boolean): Promise<void> {
    try {
      const userInfo = await this._userInfoService.getUserInfo();
      if (userInfo) {
        var expired = await this.tokenExpired();
        if (!timerRefresh && !expired) {
          return;
        }
        if (!userInfo.refresh_token) {
          return;
        }

        var env = await this._EnvironmentConfigService.EnsureNetworkConfiguration("auth-service-refreshToken");
        var refreshOptions: RefreshTokenRequestModel = {
          applicationId: env!.authenticateOptions!.appId!,
          refreshToken: userInfo.refresh_token!
        }

        var response: RefreshTokenResponseModel | null

        try {

          response = await firstValueFrom(
            this._localAccountService.localAccountRefreshTokenPost({ body: refreshOptions })
              .pipe(timeout(this.NETWORK_TIMEOUT))
          );

        } catch (error) {
      
          var err = error as any;

          if (err && err.error     && err.error.message) {
            await this._systemLogService.logMessage('User token returned error [' + err.error.message + '] user logged out');
            await this.clearUserData(false);
            setTimeout(() => {
              window.location.href = '/welcome'; // Redirects to the root URL
            }, 1000);
            return;
          }else{
            await this._PromptService.showNetworkConnectionError(error,'refresh');
            response = null;
          }
        }

        if (!response) {
          await this.setupRefreshTimer();
          return; //try another time
        }

        if (response.error) {
          await this.setupRefreshTimer();
        }

        //we have a bad token - logout the user.
        if (!response.access_token || response.access_token.length <= 0 || response.error) {
          console.error(response);
          await this._systemLogService.logMessage('User token returned error [' + JSON.stringify(response) + '] user logged out');
          await this.clearUserData(false);
          setTimeout(() => {
            window.location.href = '/welcome'; // Redirects to the root URL
          }, 1000);
          return;
        }



        var tokenResponse = response as RefreshTokenResponseModel;
        if (tokenResponse && tokenResponse.access_token && tokenResponse.access_token.length > 0 && !tokenResponse.error) {
          if (!tokenResponse.expires_in) {
            tokenResponse.expires_in = (55 * 60);
          }

          var expires_in = tokenResponse.expires_in * 0.75;
          const dt = addSeconds(new Date(), expires_in);
          const expiry = formatISO(dt);

          const token = this.parseJwt(tokenResponse.access_token);
          var tokenUser: UserData = {
            sub: token.sub,
            given_name: token.given_name,
            family_name: token.family_name,
            name: token.name,
            time: formatISO(new Date()),
            access_token: tokenResponse.access_token!,
            refresh_token: tokenResponse.refresh_token!,
            tokenExpiry: expiry,
            waitTime: expires_in,
            tokenClaims: token,
            email: token.userEmailAddress!,
            language: token.language,
            systemFunctions: token.systemFunctions
          };
          await this._userInfoService.setUserInfo(tokenUser);

          await this.setUserLoggedInAsync();

        }

      }
    } catch (error) {
      console.error(error);
      await this.setupRefreshTimer();

    }
  }


  private async setUserLoggedInAsync(): Promise<void> {

    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo) {
      await this.setupRefreshTimer();
    }
  }

  private refreshInterval: any;
  private clearRefresh() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  }

  private nextReresh: Date | null = null;
  private async singleRefreshSetup() {
    // Check if the next refresh is scheduled and still in the future
    if (this.refreshInterval && this.nextReresh && isAfter(this.nextReresh, new Date())) {
      return;
    }

    let waitTimeSeconds = 30;
    const userInfo = await this._userInfoService.getUserInfo();
    if (!userInfo) {
      return;
    }

    // Get the current time in UTC
    const utcNow = new Date();
    let end = utcNow;

    // Parse the token expiry time as a UTC date
    if (userInfo.tokenExpiry) {
      end = parseISO(userInfo.tokenExpiry);
    }

    // Calculate the difference in seconds between the token expiry and the current time
    const seconds = differenceInSeconds(end, utcNow);

    // Adjust the wait time based on the time remaining before the token expires
    if (seconds > 1) {
      waitTimeSeconds = seconds * 0.75;
    }

    // Schedule the next refresh
    this.nextReresh = addSeconds(utcNow, waitTimeSeconds);

    // Clear any existing refresh interval and set a new one
    this.clearRefresh();

    this.refreshInterval = setInterval(async () => {
      await this.refreshToken(true);
    }, waitTimeSeconds * 1000);
  }


  private refreshLock = new Semaphore(1);

  private async setupRefreshTimer() {
    var result = false;
    await this.refreshLock.callFunction(async (_: any) => {
      await this.singleRefreshSetup();
    }, []);
    return result;

  }

  public async DeleteProfile(): Promise<boolean> {
    try {
      const response = await firstValueFrom(this._localAccountService.localAccountDeleteAccountDelete().pipe(timeout(this.NETWORK_TIMEOUT)));
      //logout and clear local storage
      await this.clearBiometricLogin();
      await this.logout();
      await this.secureStorageService.clear();

      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error,'DeleteProfile');
      console.error('Unexpected error:', error);
      return false;
    }
  }

}
