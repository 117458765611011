import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { SecureStorageService } from 'src/app/services/storage/secure-storage.service';
import { SecureStorageKey } from 'src/app/services/storage/models/secure-storage-key.enum';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { TokenService } from 'src/app/services/token/token.service';
import { UserInfoService } from 'src/app/services/auth-service/user-info-service';
import { EnvironmentConfigService } from 'src/app/services/environment-config-service/environment-config.service';
import { UserData } from 'src/app/services/auth-service/user-data.model';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard {
	constructor(
		private authService: AuthService,
		public navCtrl: NavController,
		private _tokenService: TokenService,
		private _userInfoService: UserInfoService,
		private _EnvironmentConfigService: EnvironmentConfigService,
	) {
	}

	canLoad():
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
		// Return the result of allowLogin, ensuring it resolves before returning
		return this.allowLogin();
	}

	private async allowLogin(): Promise<boolean> {
		var appConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration('LoginGuard - allow login');
		if (!appConfig) {
			return await this.navigateToLogin();
		}

		let userInfo = await this._userInfoService.getUserInfo();

		if (!userInfo) {
			return await this.navigateToLogin();
		}

		var expired = await this.authService.tokenExpired();
		if (expired) {
			// Attempt to refresh the token
			const loggedIn = await this.rereshTokenAsync(userInfo);
			if (!loggedIn) {
				return await this.navigateToLogin();
			}
		}

		// If we reach this point, the user is logged in
		return true;
	}

	async navigateToLogin() {
		await this.authService.clearUserData(false);
		await this.navCtrl.navigateRoot('/welcome');
		return true; //we are changing the route so we return true
	}

	async rereshTokenAsync(userInfo: UserData): Promise<boolean> {
		try {
			var expired = await this.authService.tokenExpired();
			if (!expired)
				return true;
			const refreshToken = await this._tokenService.refreshTokenAsync();
			if (refreshToken)
				return true;  // Token refresh succeeded
			return false;
		} catch (error) {
			// Catch any unexpected errors during the token refresh process
			return false;
		}
	}
}
