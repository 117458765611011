import { ChangeDetectorRef, Component } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { MenuController, NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { DashboardService } from 'src/app/services/dashboard-service/dashboard-service';
import { EventService } from 'src/app/services/events/event.service';
import { OrganizationService } from 'src/app/services/organization-service/organization.service';
import { PromptService } from 'src/app/services/promtp-service/prompt.service';
import { TranslatorService } from 'src/app/services/translator-service/translator.service';
import { BaseComponent } from 'src/app/shared/base/base.component';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent extends BaseComponent {

  isDashboardAllowed: boolean = false;
  chatEnabled: boolean = false;
  constructor(
    protected override _navCtrl: NavController,
    protected override _changeDetectorRef: ChangeDetectorRef,
    protected override _PromptService: PromptService,
    protected override _translator: TranslatorService,
    private _DashboardService: DashboardService,
    private _authService: AuthService,
    private menu: MenuController,
    private _organizationService: OrganizationService,
    private _Events: EventService
  ) {
    super(_navCtrl, _changeDetectorRef, _PromptService, _translator);

    this._Events.organizationConfigLoaded.subscribe(async (v: any) => {

      var chatEnabled = await this._organizationService.AIChatEnabled();
      this.chatEnabled = chatEnabled;
    });

  }


  override  async ngOnInit(): Promise<void> {
    this.isDashboardAllowed = await this._DashboardService.userIsAllowed();
    await super.ngOnInit();
    this.chatEnabled = await this._organizationService.AIChatEnabled();
  }

  async logout() {
    await this.menu.close();
    await this._authService.logout();
  }

  async myProfile() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('home/myProfile');
  }

  async mediaChatbot() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('home/mediaChatbot');
  }

  async openUrl(theUrl: string) {
    await Browser.open({ url: theUrl });
  }

  async deleteAccount() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('deleteAccount');
  }

  async openNotifications() {

  }


  async dashboard() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('home/dashboard');
  }
  async gotoV2() {
    await this.menu.close();
    await this._navCtrl.navigateForward('/v2/health-check/scan-intro');
  }

  async gotoControlTest() {
    await this.menu.close();
    await this._navCtrl.navigateForward('v2/welcome/intro-page');
  }

  async gotoMedia() {
    await this.menu.close();
    await this._navCtrl.navigateRoot('home/media');
  }

  async vivaScoreLite(){
    await this.menu.close();
    await this._navCtrl.navigateRoot('viva-lite/access-code');
  }
}