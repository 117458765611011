/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MPIBaseService } from '../mpi-base-service';
import { MPIApiConfiguration } from '../mpi-api-configuration';
import { MPIStrictHttpResponse } from '../mpi-strict-http-response';

import { consumerDelete } from '../fn/consumer/consumer-delete';
import { ConsumerDelete$Params } from '../fn/consumer/consumer-delete';
import { consumerGet } from '../fn/consumer/consumer-get';
import { ConsumerGet$Params } from '../fn/consumer/consumer-get';
import { consumerOrganizationConsentIdPost } from '../fn/consumer/consumer-organization-consent-id-post';
import { ConsumerOrganizationConsentIdPost$Params } from '../fn/consumer/consumer-organization-consent-id-post';
import { consumerOrganizationLinkPost } from '../fn/consumer/consumer-organization-link-post';
import { ConsumerOrganizationLinkPost$Params } from '../fn/consumer/consumer-organization-link-post';
import { consumerOrganizationsGet } from '../fn/consumer/consumer-organizations-get';
import { ConsumerOrganizationsGet$Params } from '../fn/consumer/consumer-organizations-get';
import { consumerOrganizationUnlinkIdDelete } from '../fn/consumer/consumer-organization-unlink-id-delete';
import { ConsumerOrganizationUnlinkIdDelete$Params } from '../fn/consumer/consumer-organization-unlink-id-delete';
import { consumerPersonIdPhotoGet } from '../fn/consumer/consumer-person-id-photo-get';
import { ConsumerPersonIdPhotoGet$Params } from '../fn/consumer/consumer-person-id-photo-get';
import { consumerPersonIdThumbnailGet } from '../fn/consumer/consumer-person-id-thumbnail-get';
import { ConsumerPersonIdThumbnailGet$Params } from '../fn/consumer/consumer-person-id-thumbnail-get';
import { consumerPhotoPost } from '../fn/consumer/consumer-photo-post';
import { ConsumerPhotoPost$Params } from '../fn/consumer/consumer-photo-post';
import { consumerPost } from '../fn/consumer/consumer-post';
import { ConsumerPost$Params } from '../fn/consumer/consumer-post';
import { PatientModel } from '../models/patient-model';
import { PersonOrganizationModel } from '../models/person-organization-model';

@Injectable({ providedIn: 'root' })
export class MPIConsumerService extends MPIBaseService {
  constructor(config: MPIApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `consumerPersonIdPhotoGet()` */
  static readonly ConsumerPersonIdPhotoGetPath = '/Consumer/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdPhotoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdPhotoGet$Response(params: ConsumerPersonIdPhotoGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return consumerPersonIdPhotoGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdPhotoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdPhotoGet(params: ConsumerPersonIdPhotoGet$Params, context?: HttpContext): Observable<void> {
    return this.consumerPersonIdPhotoGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `consumerPersonIdThumbnailGet()` */
  static readonly ConsumerPersonIdThumbnailGetPath = '/Consumer/{personId}/Thumbnail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPersonIdThumbnailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdThumbnailGet$Response(params: ConsumerPersonIdThumbnailGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return consumerPersonIdThumbnailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPersonIdThumbnailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerPersonIdThumbnailGet(params: ConsumerPersonIdThumbnailGet$Params, context?: HttpContext): Observable<void> {
    return this.consumerPersonIdThumbnailGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `consumerGet()` */
  static readonly ConsumerGetPath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet$Response(params?: ConsumerGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<PatientModel>> {
    return consumerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerGet(params?: ConsumerGet$Params, context?: HttpContext): Observable<PatientModel> {
    return this.consumerGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<PatientModel>): PatientModel => r.body)
    );
  }

  /** Path part for operation `consumerPost()` */
  static readonly ConsumerPostPath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost$Response(params?: ConsumerPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return consumerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPost(params?: ConsumerPost$Params, context?: HttpContext): Observable<string> {
    return this.consumerPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `consumerDelete()` */
  static readonly ConsumerDeletePath = '/Consumer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerDelete$Response(params?: ConsumerDelete$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return consumerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerDelete(params?: ConsumerDelete$Params, context?: HttpContext): Observable<void> {
    return this.consumerDelete$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `consumerPhotoPost()` */
  static readonly ConsumerPhotoPostPath = '/Consumer/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerPhotoPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost$Response(params?: ConsumerPhotoPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return consumerPhotoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerPhotoPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerPhotoPost(params?: ConsumerPhotoPost$Params, context?: HttpContext): Observable<string> {
    return this.consumerPhotoPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `consumerOrganizationsGet()` */
  static readonly ConsumerOrganizationsGetPath = '/Consumer/Organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerOrganizationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrganizationsGet$Response(params?: ConsumerOrganizationsGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PersonOrganizationModel>>> {
    return consumerOrganizationsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerOrganizationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrganizationsGet(params?: ConsumerOrganizationsGet$Params, context?: HttpContext): Observable<Array<PersonOrganizationModel>> {
    return this.consumerOrganizationsGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PersonOrganizationModel>>): Array<PersonOrganizationModel> => r.body)
    );
  }

  /** Path part for operation `consumerOrganizationLinkPost()` */
  static readonly ConsumerOrganizationLinkPostPath = '/Consumer/OrganizationLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerOrganizationLinkPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerOrganizationLinkPost$Response(params?: ConsumerOrganizationLinkPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PersonOrganizationModel>>> {
    return consumerOrganizationLinkPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerOrganizationLinkPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerOrganizationLinkPost(params?: ConsumerOrganizationLinkPost$Params, context?: HttpContext): Observable<Array<PersonOrganizationModel>> {
    return this.consumerOrganizationLinkPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PersonOrganizationModel>>): Array<PersonOrganizationModel> => r.body)
    );
  }

  /** Path part for operation `consumerOrganizationUnlinkIdDelete()` */
  static readonly ConsumerOrganizationUnlinkIdDeletePath = '/Consumer/OrganizationUnlink/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerOrganizationUnlinkIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrganizationUnlinkIdDelete$Response(params: ConsumerOrganizationUnlinkIdDelete$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PersonOrganizationModel>>> {
    return consumerOrganizationUnlinkIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerOrganizationUnlinkIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consumerOrganizationUnlinkIdDelete(params: ConsumerOrganizationUnlinkIdDelete$Params, context?: HttpContext): Observable<Array<PersonOrganizationModel>> {
    return this.consumerOrganizationUnlinkIdDelete$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PersonOrganizationModel>>): Array<PersonOrganizationModel> => r.body)
    );
  }

  /** Path part for operation `consumerOrganizationConsentIdPost()` */
  static readonly ConsumerOrganizationConsentIdPostPath = '/Consumer/OrganizationConsent/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumerOrganizationConsentIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerOrganizationConsentIdPost$Response(params: ConsumerOrganizationConsentIdPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return consumerOrganizationConsentIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumerOrganizationConsentIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  consumerOrganizationConsentIdPost(params: ConsumerOrganizationConsentIdPost$Params, context?: HttpContext): Observable<void> {
    return this.consumerOrganizationConsentIdPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

}
