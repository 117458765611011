import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { UserInfoService } from '../auth-service/user-info-service';
import { TeamsService } from '../teams-service/teams.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  constructor(
    private authService: AuthService,
    private _userInfoService: UserInfoService,
    private _teamsService: TeamsService
  ) { 
  }

  private initialized = false;

  public async init(): Promise<void> {
    // Simulate some initialization logic (e.g., checking token, refreshing, etc.)
    this.initialized = true;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }


  public async tokenExpired(): Promise<boolean> {
    const inTeams = await this._teamsService.inTeams();
    if (inTeams) {
      return false;
    }
    return await this.authService.tokenExpired();
  }

  public async getTokenAsync(source: string): Promise<string | null> {
    const inTeams = await this._teamsService.inTeams();
    
    if (inTeams) {
      return await this._teamsService.getToken();
    }

    // Check if the token has expired
    const isTokenExpired = await this.tokenExpired();
    
    // If we have a token that is not expired, return it.
    if (!isTokenExpired) {
      return await this.authService.token();
    }

    // Otherwise, refresh the token
    return await this.refreshTokenAsync();
  }

  public async refreshTokenAsync(): Promise<string | null> {
    // Check if the token is expired before refreshing
    const isTokenExpired = await this.tokenExpired();

    if (!isTokenExpired) {
      return await this.authService.token();
    }

    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo) {
      await this.authService.refreshToken(false); // Assuming false means no user interaction required
    }

    return await this.authService.token();
  }
}
