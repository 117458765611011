import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { VivaInputModule } from 'src/app/controls/viva-input/viva-input.module';
import { VivaSelectModule } from '../controls/viva-select/viva-select.module';
import { VivaToggleModule } from '../controls/viva-toggle/viva-toggle.module';
import { VivaDateInputModule } from '../controls/viva-date-input/viva-date-input.module';
import { VivaProgressModule } from '../controls/viva-progress/viva-progress.module';
import { VivaSpinnerModule } from '../controls/viva-spinner/viva-spinner.module';
import { VivaInfoPopupModule } from '../controls/viva-info-popup/viva-info-popup.module';
import { VivaQuestionPopupModule } from '../controls/viva-question-popup/viva-question-popup.module';
import { VivaTextAreaModule } from '../controls/viva-text-area/viva-text-area.module';
import { VivaLineChartModule } from '../controls/viva-line-chart/viva-line-chart.module';
import { VivaErrorPopupModule } from '../controls/viva-error-popup/viva-error-popup.module';
import { VivaWarningPopupModule } from '../controls/viva-warning-popup copy/viva-warning-popup.module';


@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		IonicModule,
		FormsModule,
		TranslateModule,
		VivaInputModule,
		VivaToggleModule,
		VivaSelectModule,
		VivaDateInputModule,
		VivaProgressModule,
		VivaSpinnerModule,
		VivaInfoPopupModule,
		VivaQuestionPopupModule,
		VivaWarningPopupModule,
		VivaErrorPopupModule,
		VivaTextAreaModule,
		VivaLineChartModule
		
	],
	exports: [FormsModule,
		TranslateModule,
		VivaInputModule,
		VivaToggleModule,
		VivaSelectModule,
		VivaDateInputModule,
		VivaProgressModule,
		VivaSpinnerModule,
		VivaInfoPopupModule,
		VivaQuestionPopupModule,
		VivaWarningPopupModule,
		VivaErrorPopupModule,
		VivaTextAreaModule,
		VivaLineChartModule
	],
})
export class SharedModule {}