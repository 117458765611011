/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MPIStrictHttpResponse } from '../../mpi-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConsentUpdateModel } from '../../models/consent-update-model';

export interface ConsumerOrganizationConsentIdPost$Params {
  id: string;
      body?: ConsentUpdateModel
}

export function consumerOrganizationConsentIdPost(http: HttpClient, rootUrl: string, params: ConsumerOrganizationConsentIdPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, consumerOrganizationConsentIdPost.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as MPIStrictHttpResponse<void>;
    })
  );
}

consumerOrganizationConsentIdPost.PATH = '/Consumer/OrganizationConsent/{id}';
