import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { delay, Observable, Subject } from 'rxjs';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { PromptService } from 'src/app/services/promtp-service/prompt.service';
import { TranslatorService } from 'src/app/services/translator-service/translator.service';

@Component({
  selector: 'base-component',
  template: '',
})
export class BaseComponent
  implements OnInit, OnDestroy {
  protected _unsubscribeAll: Subject<any>;
  protected popup: boolean = false;
  public uniqueId: string;
  public gridActivated: boolean = true;
  public loading: boolean = false;


  constructor(
    protected _navCtrl: NavController,
    protected _changeDetectorRef: ChangeDetectorRef,
    protected _PromptService: PromptService,
    protected _translator: TranslatorService
  ) {
    this._translator.refreshRTL();

    this._unsubscribeAll = new Subject();
    this.uniqueId = Math.random().toString(36).substring(7);

    this.isWeb = Capacitor.getPlatform() == 'web';
    this.isIOS = Capacitor.getPlatform() == 'ios';
    this.isAndroid = Capacitor.getPlatform() == 'android';


  }

  public isWeb = false;
  public isIOS = false;
  public isAndroid = false;

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  async ngOnInit(): Promise<void> {
  }

  protected async throwError(
    errorObject: any,
    statusText: string,
    title: string = 'Error'
  ) {
    await this._PromptService.throwError(errorObject, statusText, title);
  }

  protected async throwErrorMessage(
    statusText: string
  ) {
    await this._PromptService.throwError(null, statusText, 'Error');
  }

  protected async showMessage(
    statusText: string,
    title: string = ''
  ) {
    await this._PromptService.showMessage(statusText);
  }

  public async showToastMessage(message: string) {
    await this._PromptService.showToastMessage(message);
  }

  public updateForm(form: FormGroup, value: any): void {
    form.patchValue(value);
  }

  public resetForm(form: FormGroup): void {
    form.reset();
  }

  public getRouteParam(
    activatedRoute: ActivatedRoute,
    parameter: string
  ): string {
    const parameterValue = activatedRoute.snapshot.params[parameter];
    if (parameterValue === undefined) return '';
    if (parameterValue === null) return '';
    if (parameterValue === 'undefined') return '';
    if (parameterValue === 'null') return '';
    if (parameterValue === '') return '';
    return parameterValue;
  }

  public getQueryParam(
    activatedRoute: ActivatedRoute,
    parameter: string
  ): string {
    const parameterValue = activatedRoute.snapshot.queryParams[parameter];
    if (parameterValue === undefined) return '';
    if (parameterValue === null) return '';
    if (parameterValue === 'undefined') return '';
    if (parameterValue === 'null') return '';
    if (parameterValue === '') return '';
    return parameterValue;
  }

  public hasValue(value: any): boolean {
    if (value === undefined) return false;
    if (value === null) return false;
    if (value === 'undefined') return false;
    if (value === 'null') return false;
    if (value === '') return false;
    return true;
  }


  public splitCamelCase(word: string): string {
    var output,
      i,
      l,
      capRe = /[A-Z]/;
    if (typeof word !== 'string') {
      throw new Error('The "word" parameter must be a string.');
    }
    output = [];
    for (i = 0, l = word.length; i < l; i += 1) {
      if (i === 0) {
        output.push(word[i].toUpperCase());
      } else {
        if (i > 0 && capRe.test(word[i])) {
          output.push(' ');
        }
        output.push(word[i]);
      }
    }
    return output.join('');
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  public canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return true;
  }
}
