/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MPIBaseService } from '../mpi-base-service';
import { MPIApiConfiguration } from '../mpi-api-configuration';
import { MPIStrictHttpResponse } from '../mpi-strict-http-response';

import { adminB2CRegisterPersonPost } from '../fn/admin/admin-b-2-c-register-person-post';
import { AdminB2CRegisterPersonPost$Params } from '../fn/admin/admin-b-2-c-register-person-post';
import { adminPersonIdB2CPersonActivePut } from '../fn/admin/admin-person-id-b-2-c-person-active-put';
import { AdminPersonIdB2CPersonActivePut$Params } from '../fn/admin/admin-person-id-b-2-c-person-active-put';
import { adminPersonIdDeActivatePut } from '../fn/admin/admin-person-id-de-activate-put';
import { AdminPersonIdDeActivatePut$Params } from '../fn/admin/admin-person-id-de-activate-put';
import { adminPersonIdDelete } from '../fn/admin/admin-person-id-delete';
import { AdminPersonIdDelete$Params } from '../fn/admin/admin-person-id-delete';
import { adminPersonIdGet } from '../fn/admin/admin-person-id-get';
import { AdminPersonIdGet$Params } from '../fn/admin/admin-person-id-get';
import { adminPersonIdGetOrganizationIdGet } from '../fn/admin/admin-person-id-get-organization-id-get';
import { AdminPersonIdGetOrganizationIdGet$Params } from '../fn/admin/admin-person-id-get-organization-id-get';
import { adminPersonIdGetOrganizationListGet } from '../fn/admin/admin-person-id-get-organization-list-get';
import { AdminPersonIdGetOrganizationListGet$Params } from '../fn/admin/admin-person-id-get-organization-list-get';
import { adminPersonIdPhotoPost } from '../fn/admin/admin-person-id-photo-post';
import { AdminPersonIdPhotoPost$Params } from '../fn/admin/admin-person-id-photo-post';
import { adminPersonIdSaveOrganizationLinkPost } from '../fn/admin/admin-person-id-save-organization-link-post';
import { AdminPersonIdSaveOrganizationLinkPost$Params } from '../fn/admin/admin-person-id-save-organization-link-post';
import { adminPersonIdUnlinkOrganizationIdDelete } from '../fn/admin/admin-person-id-unlink-organization-id-delete';
import { AdminPersonIdUnlinkOrganizationIdDelete$Params } from '../fn/admin/admin-person-id-unlink-organization-id-delete';
import { adminPost } from '../fn/admin/admin-post';
import { AdminPost$Params } from '../fn/admin/admin-post';
import { adminSearchPost } from '../fn/admin/admin-search-post';
import { AdminSearchPost$Params } from '../fn/admin/admin-search-post';
import { PatientAdminModel } from '../models/patient-admin-model';
import { PatientSearchResultModel } from '../models/patient-search-result-model';
import { PersonOrganizationModel } from '../models/person-organization-model';

@Injectable({ providedIn: 'root' })
export class MPIAdminService extends MPIBaseService {
  constructor(config: MPIApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminSearchPost()` */
  static readonly AdminSearchPostPath = '/Admin/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSearchPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost$Response(params?: AdminSearchPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PatientSearchResultModel>>> {
    return adminSearchPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSearchPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminSearchPost(params?: AdminSearchPost$Params, context?: HttpContext): Observable<Array<PatientSearchResultModel>> {
    return this.adminSearchPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PatientSearchResultModel>>): Array<PatientSearchResultModel> => r.body)
    );
  }

  /** Path part for operation `adminPersonIdGet()` */
  static readonly AdminPersonIdGetPath = '/Admin/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet$Response(params: AdminPersonIdGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<PatientAdminModel>> {
    return adminPersonIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGet(params: AdminPersonIdGet$Params, context?: HttpContext): Observable<PatientAdminModel> {
    return this.adminPersonIdGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<PatientAdminModel>): PatientAdminModel => r.body)
    );
  }

  /** Path part for operation `adminPersonIdDelete()` */
  static readonly AdminPersonIdDeletePath = '/Admin/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDelete$Response(params: AdminPersonIdDelete$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return adminPersonIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDelete(params: AdminPersonIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminPersonIdDelete$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminPost()` */
  static readonly AdminPostPath = '/Admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost$Response(params?: AdminPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return adminPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPost(params?: AdminPost$Params, context?: HttpContext): Observable<string> {
    return this.adminPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminPersonIdDeActivatePut()` */
  static readonly AdminPersonIdDeActivatePutPath = '/Admin/{personId}/DeActivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdDeActivatePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDeActivatePut$Response(params: AdminPersonIdDeActivatePut$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
    return adminPersonIdDeActivatePut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdDeActivatePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdDeActivatePut(params: AdminPersonIdDeActivatePut$Params, context?: HttpContext): Observable<void> {
    return this.adminPersonIdDeActivatePut$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminPersonIdPhotoPost()` */
  static readonly AdminPersonIdPhotoPostPath = '/Admin/{personId}/Photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdPhotoPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost$Response(params: AdminPersonIdPhotoPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return adminPersonIdPhotoPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdPhotoPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdPhotoPost(params: AdminPersonIdPhotoPost$Params, context?: HttpContext): Observable<string> {
    return this.adminPersonIdPhotoPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminPersonIdGetOrganizationListGet()` */
  static readonly AdminPersonIdGetOrganizationListGetPath = '/Admin/{personId}/GetOrganizationList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGetOrganizationListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGetOrganizationListGet$Response(params: AdminPersonIdGetOrganizationListGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PersonOrganizationModel>>> {
    return adminPersonIdGetOrganizationListGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGetOrganizationListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGetOrganizationListGet(params: AdminPersonIdGetOrganizationListGet$Params, context?: HttpContext): Observable<Array<PersonOrganizationModel>> {
    return this.adminPersonIdGetOrganizationListGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PersonOrganizationModel>>): Array<PersonOrganizationModel> => r.body)
    );
  }

  /** Path part for operation `adminPersonIdGetOrganizationIdGet()` */
  static readonly AdminPersonIdGetOrganizationIdGetPath = '/Admin/{personId}/GetOrganization/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdGetOrganizationIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGetOrganizationIdGet$Response(params: AdminPersonIdGetOrganizationIdGet$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<PersonOrganizationModel>> {
    return adminPersonIdGetOrganizationIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdGetOrganizationIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdGetOrganizationIdGet(params: AdminPersonIdGetOrganizationIdGet$Params, context?: HttpContext): Observable<PersonOrganizationModel> {
    return this.adminPersonIdGetOrganizationIdGet$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<PersonOrganizationModel>): PersonOrganizationModel => r.body)
    );
  }

  /** Path part for operation `adminPersonIdSaveOrganizationLinkPost()` */
  static readonly AdminPersonIdSaveOrganizationLinkPostPath = '/Admin/{personId}/SaveOrganizationLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdSaveOrganizationLinkPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdSaveOrganizationLinkPost$Response(params: AdminPersonIdSaveOrganizationLinkPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return adminPersonIdSaveOrganizationLinkPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdSaveOrganizationLinkPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminPersonIdSaveOrganizationLinkPost(params: AdminPersonIdSaveOrganizationLinkPost$Params, context?: HttpContext): Observable<string> {
    return this.adminPersonIdSaveOrganizationLinkPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminPersonIdUnlinkOrganizationIdDelete()` */
  static readonly AdminPersonIdUnlinkOrganizationIdDeletePath = '/Admin/{personId}/UnlinkOrganization/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdUnlinkOrganizationIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdUnlinkOrganizationIdDelete$Response(params: AdminPersonIdUnlinkOrganizationIdDelete$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<Array<PersonOrganizationModel>>> {
    return adminPersonIdUnlinkOrganizationIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdUnlinkOrganizationIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdUnlinkOrganizationIdDelete(params: AdminPersonIdUnlinkOrganizationIdDelete$Params, context?: HttpContext): Observable<Array<PersonOrganizationModel>> {
    return this.adminPersonIdUnlinkOrganizationIdDelete$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<Array<PersonOrganizationModel>>): Array<PersonOrganizationModel> => r.body)
    );
  }

  /** Path part for operation `adminB2CRegisterPersonPost()` */
  static readonly AdminB2CRegisterPersonPostPath = '/Admin/B2CRegisterPerson';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminB2CRegisterPersonPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminB2CRegisterPersonPost$Response(params?: AdminB2CRegisterPersonPost$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return adminB2CRegisterPersonPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminB2CRegisterPersonPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminB2CRegisterPersonPost(params?: AdminB2CRegisterPersonPost$Params, context?: HttpContext): Observable<string> {
    return this.adminB2CRegisterPersonPost$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminPersonIdB2CPersonActivePut()` */
  static readonly AdminPersonIdB2CPersonActivePutPath = '/Admin/{personId}/B2CPersonActive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPersonIdB2CPersonActivePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdB2CPersonActivePut$Response(params: AdminPersonIdB2CPersonActivePut$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<string>> {
    return adminPersonIdB2CPersonActivePut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPersonIdB2CPersonActivePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminPersonIdB2CPersonActivePut(params: AdminPersonIdB2CPersonActivePut$Params, context?: HttpContext): Observable<string> {
    return this.adminPersonIdB2CPersonActivePut$Response(params, context).pipe(
      map((r: MPIStrictHttpResponse<string>): string => r.body)
    );
  }

}
