import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VivaLineChartComponent } from "./viva-line-chart.component";
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations:[
        VivaLineChartComponent
    ],
    exports:[
        VivaLineChartComponent
    ],
    imports:[
        NgApexchartsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
    ]
})
export class VivaLineChartModule{}