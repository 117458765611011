/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WearablesBaseService } from '../wearables-base-service';
import { WearablesApiConfiguration } from '../wearables-api-configuration';
import { WearablesStrictHttpResponse } from '../wearables-strict-http-response';

import { BackFillResponseModel } from '../models/back-fill-response-model';
import { GoalsModel } from '../models/goals-model';
import { MemberStatistics } from '../models/member-statistics';
import { profileBackFillGetPlatformGet } from '../fn/profile/profile-back-fill-get-platform-get';
import { ProfileBackFillGetPlatformGet$Params } from '../fn/profile/profile-back-fill-get-platform-get';
import { profileConfigurationGet } from '../fn/profile/profile-configuration-get';
import { ProfileConfigurationGet$Params } from '../fn/profile/profile-configuration-get';
import { profileDailySummaryPost } from '../fn/profile/profile-daily-summary-post';
import { ProfileDailySummaryPost$Params } from '../fn/profile/profile-daily-summary-post';
import { profileDeviceConnectedPost } from '../fn/profile/profile-device-connected-post';
import { ProfileDeviceConnectedPost$Params } from '../fn/profile/profile-device-connected-post';
import { profileDeviceLogoFileNameGet } from '../fn/profile/profile-device-logo-file-name-get';
import { ProfileDeviceLogoFileNameGet$Params } from '../fn/profile/profile-device-logo-file-name-get';
import { profileDisconnectPost } from '../fn/profile/profile-disconnect-post';
import { ProfileDisconnectPost$Params } from '../fn/profile/profile-disconnect-post';
import { profileMemberGoalGet } from '../fn/profile/profile-member-goal-get';
import { ProfileMemberGoalGet$Params } from '../fn/profile/profile-member-goal-get';
import { profileMemberGoalPost } from '../fn/profile/profile-member-goal-post';
import { ProfileMemberGoalPost$Params } from '../fn/profile/profile-member-goal-post';
import { profileStatisticsGet } from '../fn/profile/profile-statistics-get';
import { ProfileStatisticsGet$Params } from '../fn/profile/profile-statistics-get';
import { profileSummaryPost } from '../fn/profile/profile-summary-post';
import { ProfileSummaryPost$Params } from '../fn/profile/profile-summary-post';
import { SessionConfigResponseModel } from '../models/session-config-response-model';
import { StatisticsModel } from '../models/statistics-model';
import { SummaryModel } from '../models/summary-model';

@Injectable({ providedIn: 'root' })
export class WearablesProfileService extends WearablesBaseService {
  constructor(config: WearablesApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `profileDailySummaryPost()` */
  static readonly ProfileDailySummaryPostPath = '/Profile/DailySummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileDailySummaryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileDailySummaryPost$Response(params?: ProfileDailySummaryPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<StatisticsModel>> {
    return profileDailySummaryPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileDailySummaryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileDailySummaryPost(params?: ProfileDailySummaryPost$Params, context?: HttpContext): Observable<StatisticsModel> {
    return this.profileDailySummaryPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<StatisticsModel>): StatisticsModel => r.body)
    );
  }

  /** Path part for operation `profileBackFillGetPlatformGet()` */
  static readonly ProfileBackFillGetPlatformGetPath = '/Profile/BackFillGet/{platform}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileBackFillGetPlatformGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileBackFillGetPlatformGet$Response(params: ProfileBackFillGetPlatformGet$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<BackFillResponseModel>> {
    return profileBackFillGetPlatformGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileBackFillGetPlatformGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileBackFillGetPlatformGet(params: ProfileBackFillGetPlatformGet$Params, context?: HttpContext): Observable<BackFillResponseModel> {
    return this.profileBackFillGetPlatformGet$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<BackFillResponseModel>): BackFillResponseModel => r.body)
    );
  }

  /** Path part for operation `profileMemberGoalGet()` */
  static readonly ProfileMemberGoalGetPath = '/Profile/MemberGoal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileMemberGoalGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileMemberGoalGet$Response(params?: ProfileMemberGoalGet$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<GoalsModel>> {
    return profileMemberGoalGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileMemberGoalGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileMemberGoalGet(params?: ProfileMemberGoalGet$Params, context?: HttpContext): Observable<GoalsModel> {
    return this.profileMemberGoalGet$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<GoalsModel>): GoalsModel => r.body)
    );
  }

  /** Path part for operation `profileMemberGoalPost()` */
  static readonly ProfileMemberGoalPostPath = '/Profile/MemberGoal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileMemberGoalPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileMemberGoalPost$Response(params?: ProfileMemberGoalPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<GoalsModel>> {
    return profileMemberGoalPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileMemberGoalPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileMemberGoalPost(params?: ProfileMemberGoalPost$Params, context?: HttpContext): Observable<GoalsModel> {
    return this.profileMemberGoalPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<GoalsModel>): GoalsModel => r.body)
    );
  }

  /** Path part for operation `profileDeviceLogoFileNameGet()` */
  static readonly ProfileDeviceLogoFileNameGetPath = '/Profile/DeviceLogo/{fileName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileDeviceLogoFileNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileDeviceLogoFileNameGet$Response(params: ProfileDeviceLogoFileNameGet$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<void>> {
    return profileDeviceLogoFileNameGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileDeviceLogoFileNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileDeviceLogoFileNameGet(params: ProfileDeviceLogoFileNameGet$Params, context?: HttpContext): Observable<void> {
    return this.profileDeviceLogoFileNameGet$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `profileConfigurationGet()` */
  static readonly ProfileConfigurationGetPath = '/Profile/Configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileConfigurationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileConfigurationGet$Response(params?: ProfileConfigurationGet$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<SessionConfigResponseModel>> {
    return profileConfigurationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileConfigurationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileConfigurationGet(params?: ProfileConfigurationGet$Params, context?: HttpContext): Observable<SessionConfigResponseModel> {
    return this.profileConfigurationGet$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<SessionConfigResponseModel>): SessionConfigResponseModel => r.body)
    );
  }

  /** Path part for operation `profileDisconnectPost()` */
  static readonly ProfileDisconnectPostPath = '/Profile/Disconnect';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileDisconnectPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileDisconnectPost$Response(params?: ProfileDisconnectPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<SessionConfigResponseModel>> {
    return profileDisconnectPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileDisconnectPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileDisconnectPost(params?: ProfileDisconnectPost$Params, context?: HttpContext): Observable<SessionConfigResponseModel> {
    return this.profileDisconnectPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<SessionConfigResponseModel>): SessionConfigResponseModel => r.body)
    );
  }

  /** Path part for operation `profileDeviceConnectedPost()` */
  static readonly ProfileDeviceConnectedPostPath = '/Profile/DeviceConnected';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileDeviceConnectedPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileDeviceConnectedPost$Response(params?: ProfileDeviceConnectedPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<void>> {
    return profileDeviceConnectedPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileDeviceConnectedPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileDeviceConnectedPost(params?: ProfileDeviceConnectedPost$Params, context?: HttpContext): Observable<void> {
    return this.profileDeviceConnectedPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `profileSummaryPost()` */
  static readonly ProfileSummaryPostPath = '/Profile/Summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileSummaryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileSummaryPost$Response(params?: ProfileSummaryPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<SummaryModel>> {
    return profileSummaryPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileSummaryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  profileSummaryPost(params?: ProfileSummaryPost$Params, context?: HttpContext): Observable<SummaryModel> {
    return this.profileSummaryPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<SummaryModel>): SummaryModel => r.body)
    );
  }

  /** Path part for operation `profileStatisticsGet()` */
  static readonly ProfileStatisticsGetPath = '/Profile/Statistics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profileStatisticsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileStatisticsGet$Response(params?: ProfileStatisticsGet$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<MemberStatistics>> {
    return profileStatisticsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profileStatisticsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profileStatisticsGet(params?: ProfileStatisticsGet$Params, context?: HttpContext): Observable<MemberStatistics> {
    return this.profileStatisticsGet$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<MemberStatistics>): MemberStatistics => r.body)
    );
  }

}
