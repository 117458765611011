/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MPIStrictHttpResponse } from '../../mpi-strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ConsumerDelete$Params {
}

export function consumerDelete(http: HttpClient, rootUrl: string, params?: ConsumerDelete$Params, context?: HttpContext): Observable<MPIStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, consumerDelete.PATH, 'delete');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as MPIStrictHttpResponse<void>;
    })
  );
}

consumerDelete.PATH = '/Consumer';
